<script>
  import { onMount } from "svelte";
  let today = '';
  let menu = [];
  onMount(async () => {
    let response = await getFile();
    if(response == null)
      return;
    today = response[0].toLocaleDateString(undefined, {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'});
    response = response[1];
    let data = await response.text();
    menu = parseCsv(data);
  });

  /**
   *Vytvoří pole objektů typu {type: 'polévka'|'hlavní chod'|'bar', values: string[]}
   *
   */
  const parseCsv = (data) => {
    return data.split("\n").reduce((acc, item) => {
      let parts = item.split(";");
      if (parts.length < 2) return acc;
      let menuItemType = parts[0];
      let menuItemValue = parts[1].replace("\r", "");
      let menuItemPrice = parts[2];

      if (!menuItemValue || menuItemValue == "0") return acc;

      let existsIndex = acc.findIndex((x) => x.type == menuItemType);
      if (existsIndex == -1)
        acc.push({
          type: menuItemType,
          values: [
            {
              text: menuItemValue,
              price: menuItemPrice,
            },
          ],
        });
      else
        acc[existsIndex].values.push({
          text: menuItemValue,
          price: menuItemPrice,
        });
      return acc;
    }, []);
  };

  const getFile = async () => {
    let getNextDay = async() => {
      for (let i = 1; i <= 7; i++) {
        nowDate.setDate(nowDate.getDate() + 1);
        let response = await fetch(
          formatFileName(nowDate.toISOString().slice(0, 10))
        );
        if(response.status == 200)
          return [nowDate, response];
      }
    }
    let nowDate = new Date();
    let treshold = new Date();
    treshold.setHours(14);
    treshold.setMinutes(30);
    treshold.setSeconds(0);
    console.log(nowDate);
    if (nowDate < treshold) {
      //dnešní menu
      let response = await fetch(formatFileName(nowDate.toISOString().slice(0, 10)));
      if(response.status == 200)
        return [nowDate, response];
      else
        return getNextDay();
    } else {
      //menu následujícího pracovního dne
      return getNextDay();
    }
  };

  const formatFileName = (name) => {
    return "../daily-menu-data/" + name + ".csv";
  };
</script>

<main>
  <div class="title">
    <h2>Denní nabídka</h2>
    {#if !!today}
      <h4>{today}</h4>
    {/if}
  </div>
  {#if menu.length == 0}
    <p class="not-found">Pro dnešní den není dostupné žádné menu</p>
  {:else}
    {#each menu as menuItem}
      <div class="menu-card">
        <p class="title">{menuItem.type}</p>
        {#each menuItem.values as v}
          <div class="item-row">
            <p class="item">{v.text}</p>
            {#if !!v.price}
              <p class="price">{v.price}</p>
            {/if}
          </div>
        {/each}
      </div>
    {/each}
  {/if}
</main>
